import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from '../../../services/repository/repository.service';
import { HomePage } from '../../home/home.page';
import { Api } from '../../../../api/api';
import Utils from '../../../../utils';
import Venue from '../../../../models/Venue';
import { PreorderType } from '../../../../enums/PreorderType';
import RepositoryDirective from '../../repository-directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../../utils/order-utils';
import { AnalyticsUtils } from '../../../../utils/analytics-utils';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MenuPage } from '../../menu/menu.page';

@Component({
	selector: 'app-success',
	templateUrl: './success.page.html',
	styleUrls: ['./success.page.scss'],
})
export class SuccessPage extends RepositoryDirective implements OnInit {
	static url = 'payment/success';
	static urlWithPaymentParam = 'payment/success/:payment';
	static ORDER_ID_PARAM = 'order';
	moment = moment;
	utils = Utils;
	orderUtils = OrderUtils;
	pt = PreorderType;
	orderVenue: Venue;
	error: any;
	orderId: string;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private alertCtrl: AlertController,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private analytics: AngularFireAnalytics,
	) {
		super(repository);
	}

	static async navigate(router: Router, order: string, payment: string) {
		await router.navigate([this.url + '/' + payment], {
			queryParams: {
				order,
			},
		});
	}

	ngOnInit() {
		super.ngOnInit();
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
	}

	async onInitFinish() {
		super.onInitFinish();
		this.route.queryParams.subscribe(params => {
			console.log({
				params,
			});
			if (this.verifiedOrder && this.verifiedOrder._id && !params.order) {
				this.orderId = this.verifiedOrder._id;
			} else {
				this.orderId = params.order;
			}
			this.reloadOrder();
		});
	}

	async reloadOrder(attempt: number = 0, error = null) {
		this.error = null;
		if (attempt > 10) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000,
			});
			return;
		}
		if (attempt > 0) {
			await Utils.sleep(200);
		}
		Api.getOrder(this.orderId)
			.then(async orderResponse => {
				this.repository.verifiedOrder.emit(orderResponse.data);
				if (!this.verifiedOrder.isPayed) {
					await this.reloadOrder(attempt + 1, this.translate.instant('payment_success_page.please_reload'));
					return;
				}
				AnalyticsUtils.paymentSuccess(this.analytics);
				try {
					if (this.venue && this.venue._id === this.verifiedOrder.venue) {
						this.orderVenue = this.venue;
					} else {
						this.orderVenue = (await Api.getLazyVenue(this.verifiedOrder.venue)).data;
					}
				} catch (e) {
					console.error(e);
					await this.reloadOrder(attempt + 1, error ? error : e);
				}
			})
			.catch(async err => {
				console.error(err);
				await this.reloadOrder(attempt + 1, err);
			});
	}

	home() {
		if (this.error) {
			this.reloadOrder();
			return;
		}
		HomePage.navigate(this.router, this.repository);
	}
	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
