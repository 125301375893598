import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../models/OptionGroup';
import ArticleOption from '../../../models/ArticleOption';
import Article from '../../../models/Article';
import Utils from 'src/utils';
import { OrderType } from '../../../enums/OrderType';
import { PreorderType } from '../../../enums/PreorderType';

@Component({
	selector: 'app-article-option-group-overlay',
	templateUrl: './article-option-group-overlay.component.html',
	styleUrls: ['article-option-group-overlay.component.scss'],
})
export class ArticleOptionGroupOverlayComponent {
	@Input() optionGroup: OptionGroup;
	@Input() currency: string;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;

	Utils = Utils;

	constructor() {
	}

	selectedArticle: Article;

	select(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.quantity = 1;
		articleOption.group = this.optionGroup._id;
		this.selection.emit(articleOption);
	}

	selectArticle(article: Article) {
		this.selectedArticle = article
		this.select(article);
	}

	get price() {
		return Utils.getPrice(
			this.optionGroup.articles.find(article => Utils.getPrice(article, OrderType.PREORDER, this.preorderType) > 0),
			OrderType.PREORDER, this.preorderType,
		);
	}
}
