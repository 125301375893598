import { environment } from '../environments/environment';
import axios, { AxiosPromise } from 'axios';
import Venue from '../models/Venue';
import Order from '../models/Order';
import Payment from '../models/Payment';
import Customer from '../models/Customer';
import MyOrdersResponse from '../models/MyOrdersResponse';
import Slot from '../models/Slot';
import ArticleCategory from '../models/ArticleCategory';
import OptionGroup from '../models/OptionGroup';
import { isMongoId } from 'validator';

import { customerBody, orderBody, paymentBody } from '../models/Converter';
import PromoCode from '../models/PromoCode';
import { Information } from '../models/Information';
import { AuthLoginResponse } from '../models/AuthLoginResponse';
import { Legal } from '../models/Legal';
import { AuthStrategy } from '../models/AuthStrategy';
import { CustomerAuth } from '../models/CustomerAuth';
import { Gender } from '../enums/Gender';
import { EventEmitter } from '@angular/core';

const ORDER_SERVICE = '/v2/order/';
const CUSTOMER_SERVICE = '/v1/customer/'
const GENERAL_SERVICE = '/v1/general/';
const PAYMENT_SERVICE = '/v3/payment/';
const AUTH_SERVICE = '/v1/auth/';

const VENUE = 'venue/';
const LOGIN = 'login/';

const LOGIN_ENDPOINT = AUTH_SERVICE + LOGIN;
const CUSTOMER_ENDPOINT = CUSTOMER_SERVICE;
const VENUE_ENDPOINT = GENERAL_SERVICE + VENUE;
const ORDER_ENDPOINT = ORDER_SERVICE;
const TEST_ORDER_ENDPOINT = ORDER_SERVICE + 'test/';
const SLOT_ENDPOINT = ORDER_ENDPOINT + 'slot/';
const CUSTOMER_LOGIN_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/login';
const CUSTOMER_LOGOUT_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/logout';
const CUSTOMER_REGISTER_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/register';
const CUSTOMER_VERIFY_EMAIL_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/verify/email';
const CUSTOMER_SEND_EMAIL_VERIFICATION_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/verify/resend';
const CUSTOMER_ONE_TIME_LOGIN_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/token/code';
const CUSTOMER_REFRESH_AUTH_TOKEN_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/token/refresh';
const CUSTOMER_FORGOT_PASSWORD_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/password/forgot/request';
const CUSTOMER_RESET_PASSWORD_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/password/forgot/reset';
const CUSTOMER_DELETE_ACCOUNT_ENDPOINT = CUSTOMER_ENDPOINT;
const MAINTENANCE_ENDPOINT = GENERAL_SERVICE + 'maintenance/status';
const sanitizeId = (id: string) => {
	if (!id.startsWith(environment.customerGroup + '_') && !isMongoId(id)) {
		return environment.customerGroup + '_' + id;
	}
	return id;
};

const authHeader = 'Authorization';
class ApiService {
	axios = axios.create();

	onTokenRefreshed = new EventEmitter<CustomerAuth>();
	authToken: string;
	refreshToken: string;
	constructor() {
		this.axios.defaults.baseURL = environment.baseUrl;
		this.axios.interceptors.request.use(
			async config => {
				if (config.url === TEST_ORDER_ENDPOINT) {
					return config;
				}
				if (this.authToken) {
					config.headers[authHeader] = 'Bearer ' + this.authToken;
				}
				return config;
			},
			error => Promise.reject(error)
		);
		this.axios.interceptors.response.use(response => response, error => {
			const originalRequest = error.config;
			if (error.response.status === 401) {
				if (originalRequest.url === TEST_ORDER_ENDPOINT
					|| originalRequest.url === CUSTOMER_LOGOUT_ENDPOINT
					|| originalRequest.url === CUSTOMER_LOGIN_ENDPOINT) {
					return Promise.reject(error);
				}
				if (originalRequest.url === CUSTOMER_REFRESH_AUTH_TOKEN_ENDPOINT) {
					console.error('auth tokes couldn\'t be reissued');
					this.onTokenRefreshed.emit(null);
					return Promise.reject(error);
				} else if (!originalRequest._retry && this.onTokenRefreshed && this.refreshToken) {
					console.log('refreshing auth token with refreshToken');
					originalRequest._retry = true;
					return this.refreshAuthToken().then(res => {
						console.log(res);
						console.log('token refreshed successfuly');
						this.onTokenRefreshed.emit(res.data);
					}).catch(err => {
						console.log('token refreshed failed');
						this.onTokenRefreshed.emit(null);
					});
				}
				this.onTokenRefreshed.emit(null);
			}
			return Promise.reject(error);
		});
	}

	public authLogin(authLogin: { email: string, password: string }): AxiosPromise<AuthLoginResponse> {
		return this.axios.post(LOGIN_ENDPOINT, authLogin);
	}

	public createOrder(order: Order): AxiosPromise<Order> {
		return this.axios.post(ORDER_ENDPOINT, orderBody(order));
	}

	public createTestOrder(authToken: string, order: Order): AxiosPromise<{ order: Order, payment: string }> {
		if (environment.flavor === 'prodRelease') {
			order.flavor = 'prodDebug';
		}
		return this.axios.post(TEST_ORDER_ENDPOINT + order.venue, orderBody(order), {
			headers: {
				Authorization: 'Bearer ' + authToken,
			},
		});
	}

	public getAllVenues(articles: boolean): AxiosPromise<Venue[]> {
		return this.axios.get(VENUE_ENDPOINT + `?customerGroup=${environment.customerGroup}&articles=${articles}`);
	}

	public getVenues(lat: number, lng: number, distance: number, articles: boolean): AxiosPromise<Venue[]> {
		return this.axios.get(
			VENUE_ENDPOINT + `?lat=${lat}&lng=${lng}&distance=${distance}&articles=${articles}&customerGroup=${environment.customerGroup}`,
		);
	}

	public getVenuesBy(postalCode: string): AxiosPromise<Venue[]> {
		return this.axios.get(VENUE_ENDPOINT + '?deliveryPostalCode=' + postalCode+ '&customerGroup=' + environment.customerGroup);
	}

	public getLazyVenue(venueId: string): AxiosPromise<Venue> {
		return this.axios.get(VENUE_ENDPOINT + sanitizeId(venueId));
	}

	public getCategoriesWithArticles(venue: string, articles: boolean): AxiosPromise<ArticleCategory[]> {
		return this.axios.get(GENERAL_SERVICE + `articlecategory/?venue=${venue}&articles=${articles}`);
	}

	public getOptionGroupsByVenue(venue: string): AxiosPromise<OptionGroup[]> {
		return this.axios.get(GENERAL_SERVICE + 'articleoption/byvenue/' + venue);
	}

	public getCustomerOrders(userUid: string): AxiosPromise<MyOrdersResponse> {
		return this.axios.get(PAYMENT_SERVICE + 'customer/' + sanitizeId(userUid));
	}

	public async createPayment(payment: Payment): Promise<Payment> {
		payment._id = undefined;
		return (await this.axios.post(PAYMENT_SERVICE, paymentBody(payment))).data;
	}

	public async finishPaypalPayment(paypalOrderId: string): Promise<Payment> {
		return (await this.axios.patch(PAYMENT_SERVICE + paypalOrderId + '/paypal/pay')).data;
	}


	public getSlots(venue: string, from: string, to: string): AxiosPromise<Slot[]> {
		return this.axios.get(SLOT_ENDPOINT + venue + '?from=' + from + '&to=' + to);
	}


	public getOrder(order: string): AxiosPromise<Order> {
		return this.axios.get(ORDER_ENDPOINT + order);
	}

	// Customer

	public getCustomer(): AxiosPromise<Customer> {
		return this.axios.get(CUSTOMER_ENDPOINT);
	}
	public patchCustomer(customer: Customer): AxiosPromise<Customer> {
		customer._id = undefined;
		return this.axios.patch(CUSTOMER_ENDPOINT, customerBody(customer));
	}
	public signIn(strategy: AuthStrategy, customerGroup: string): AxiosPromise<{ url: string, isRedirect: boolean }> {
		return this.axios.post(CUSTOMER_LOGIN_ENDPOINT, {
			strategy,
			customerGroup
		});
	}
	public signInAnonymous(uid: string, customerGroup: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_LOGIN_ENDPOINT, {
			strategy: AuthStrategy.ANONYMOUS,
			uid: uid ? uid : undefined,
			customerGroup
		});
	}
	public signInWithCredentials(email: string, password: string, customerGroup: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_LOGIN_ENDPOINT, {
			strategy: AuthStrategy.CREDENTIALS,
			customerGroup,
			email,
			password
		});
	}
	public signUpWithCredentials(
		name: string,
		gender: Gender | null,
		email: string,
		password: string,
		customerGroup: string
	): AxiosPromise<Customer> {
		if(environment.isAspasiaBuild) {
			return this.axios.post(CUSTOMER_REGISTER_ENDPOINT, {
				strategy: AuthStrategy.CREDENTIALS,
				customerGroup,
				email,
				password,
				name,
			});
		} else {
			return this.axios.post(CUSTOMER_REGISTER_ENDPOINT, {
				strategy: AuthStrategy.CREDENTIALS,
				customerGroup,
				email,
				password,
				name,
				gender
			});
		}
	}
	public verifyEmail(token: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_VERIFY_EMAIL_ENDPOINT, {
			token
		});
	}
	public forgotPassword(email: string, customerGroup: string): AxiosPromise {
		return this.axios.post(CUSTOMER_FORGOT_PASSWORD_ENDPOINT, {
			email, customerGroup
		});
	}
	public oneTimeLogin(id: string, authCode: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_ONE_TIME_LOGIN_ENDPOINT, {
			id, authCode
		});
	}
	public logout(): AxiosPromise {
		return this.axios.post(CUSTOMER_LOGOUT_ENDPOINT);
	}
	public resetPassword(token: string, password: string): AxiosPromise {
		return this.axios.post(CUSTOMER_RESET_PASSWORD_ENDPOINT, {
			token, password
		});
	}
	public refreshAuthToken(): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_REFRESH_AUTH_TOKEN_ENDPOINT, {
			refreshToken: this.refreshToken
		});
	}
	public sendEmailVerification(email: string, customerGroup: string): AxiosPromise {
		return this.axios.post(CUSTOMER_SEND_EMAIL_VERIFICATION_ENDPOINT, {
			email, customerGroup
		});
	}
	public deleteAccount(): AxiosPromise {
		return this.axios.delete(CUSTOMER_DELETE_ACCOUNT_ENDPOINT);
	}

	public applyPromoCode(order: Order): AxiosPromise<Order> {
		return this.axios.post(ORDER_ENDPOINT + 'promoCode', orderBody(order));
	}

	public getPromoCode(body: { code: string; venue: string }): AxiosPromise<PromoCode> {
		return this.axios.post(GENERAL_SERVICE + 'promoCode/code', body);
	}

	public isMaintenanceActive(): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			try {
				resolve((await this.axios.get(MAINTENANCE_ENDPOINT)).data);
			} catch (e) {
				reject(e);
			}
		});
	}

	public getInformations(): AxiosPromise<Information[]> {
		return this.axios.post(GENERAL_SERVICE + 'information/url', {
			url: window.location.hostname,
		});
	}

	public getPayment(payment: string): AxiosPromise<Payment> {
		return this.axios.get(PAYMENT_SERVICE + payment);
	}

	public getLegal(venue: string): AxiosPromise<Legal> {
		return this.axios.get(VENUE_ENDPOINT + venue + '/legal');
	}
}

export let Api = new ApiService();
export { PAYMENT_SERVICE, sanitizeId };
