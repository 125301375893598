import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-short-info-modal',
	templateUrl: './short-info-modal.component.html',
	styleUrls: ['short-info-modal.component.scss'],
})
export class ShortInfoModalComponent {
	desc: string;

	constructor(private modalCtrl: ModalController) {
	}

	static async show(modalCtrl: ModalController, desc: string) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height align-bottom-only-mobile',
			component: ShortInfoModalComponent,
			componentProps: {
				desc: desc.replace('<a href', '<a target="_blank" href'),
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
