import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SignUpPage } from '../sign-up/sign-up.page';
import validator from 'validator';
import { RepositoryService } from '../../services/repository/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderPage } from '../order/order.page';
import { AlertController } from '@ionic/angular';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { Api } from '../../../api/api';
import { environment } from '../../../environments/environment';
import { delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from '../../../utils/validation-utils';
import { AccountPage } from '../account/account.page';
import { Subscription } from 'rxjs';
import { AuthStrategy } from '../../../models/AuthStrategy';
import { EmailAction } from '../email-action/email-action.page';

@Component({
	selector: 'app-sign-in',
	templateUrl: '../../../projects/purino/pages/sign-in/sign-in.page.html',
	styleUrls: ['sign-in.page.scss'],
})
export class SignInPage implements OnInit, OnDestroy {
	static url = 'sign-in';
	isValid = false;
	loading = false;
	showPassword = false;
	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private alertCtrl: AlertController,
		private route: ActivatedRoute
	) {
		this.subscription = repository.customerAuth.subscribe(customerAuth => {
			if (customerAuth && customerAuth.customer && !customerAuth.customer.isAnonymous) {
				AccountPage.navigate(router);
				this.subscription.unsubscribe();
			}
		});
	}

	// tslint:disable-next-line:variable-name
	private _email = '';

	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
		this.validate();
	}

	// tslint:disable-next-line:variable-name
	private _password = '';

	get password(): string {
		return this._password;
	}

	set password(value: string) {
		this._password = value.replace(' ', '');
		this.validate();
	}

	static async navigate(router: Router, redirectToOrder: boolean = false) {
		await router.navigateByUrl(SignInPage.url, {
			state: {
				redirectToOrder,
			},
		});
	}

	static async navigateWithParams(router: Router, queryParams: Params) {
		await router.navigate([SignInPage.url], {
			queryParams,
			replaceUrl: true,
		});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(async params => {
			if (params.id && params.auth) {
				this.processOneTimeLoginToken(params.id, params.auth);
				return;
			}
			if (params.mode === EmailAction.RESET_PASSWORD && params.token) {
				await this.showNewPasswordDialog(params.token);
			}
		});
	}

	async processOneTimeLoginToken(id: string, auth: string) {
		this.loading = true;
		try {
			const res = (await Api.oneTimeLogin(id, auth)).data;
			this.repository.customerAuth.emit(res);
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	signUp() {
		SignUpPage.navigate(this.router);
	}

	validate() {
		this.isValid = this.password && this.password.length > 5 && validator.isEmail(this.email);
	}

	async signIn() {
		this.validate();
		if (!this.isValid) {
			this.snackbarCtrl.open(this.translate.instant('errors.auth_error'), null, {
				duration: 5000,
			});
			return;
		}
		this.loading = true;
		try {
			const response = (await Api.signInWithCredentials(this.email, this.password, environment.customerGroup)).data;
			this.repository.customerAuth.emit(response);
			if (history.state.redirectToOrder) {
				await OrderPage.navigate(this.router);
			} else if (this.repository._order === null) {
				await HomePage.navigate(this.router);
			} else {
				await MenuPage.navigate(this.router);
			}
			this.snackbarCtrl.open(this.translate.instant('sign_in.success'), null, {
				duration: 5000,
			});
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('sign_in.error'), null, {
				duration: 5000,
			});
			console.error(e);
		}
		this.loading = false;
	}

	showResetPasswordDialog() {
		this.alertCtrl
			.create({
				header: this.translate.instant('reset_password.header'),
				message: this.translate.instant('reset_password.msg'),
				inputs: [
					{
						name: 'email',
						placeholder: this.translate.instant('reset_password.email'),
						type: 'email',
					},
				],
				buttons: [
					{
						text: this.translate.instant('reset_password.cancel_btn'),
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: this.translate.instant('reset_password.ok_btn'),
						handler: async result => {
							if (validator.isEmail(result.email)) {
								try {
									await Api.forgotPassword(result.email, environment.customerGroup)
									this.snackbarCtrl.open(this.translate.instant('reset_password.success'), null, {
										duration: 2000,
									});
								} catch (err) {
									this.snackbarCtrl.open(this.translate.instant('reset_password.error'), null, {
										duration: 2000,
									});
									console.log(err);
								}
							} else {
								this.showResetPasswordDialog();
							}
						},
					},
				],
			})
			.then(alert => alert.present());
	}

	async showNewPasswordDialog(resetToken: string) {
		const passwordResetAlert = await this.alertCtrl.create({
				header: this.translate.instant('new_password.header'),
				message: this.translate.instant('new_password.msg'),
				inputs: [
					{
						name: 'password1',
						placeholder: this.translate.instant('new_password.password'),
						type: 'password',
					},
					{
						name: 'password2',
						placeholder: this.translate.instant('new_password.re_password'),
						type: 'password',
					},
				],
				buttons: [
					{
						text: this.translate.instant('new_password.cancel_btn'),
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: this.translate.instant('new_password.ok_btn'),
						handler: async result => {
							if (result.password1 !== result.password2) {
								this.snackbarCtrl.open(this.translate.instant('new_password.password_match_error'), null, {
									duration: 2000,
								});
								await delay(2000);
								await this.showNewPasswordDialog(resetToken);
								return;
							}
							if (!ValidationUtils.validatePassword(result.password1)) {
								this.snackbarCtrl.open(this.translate.instant('new_password.password_error'), null, {
									duration: 6000,
								});
								await delay(2000);
								await this.showNewPasswordDialog(resetToken);
								return;
							}
							try {
								await Api.resetPassword(resetToken, result.password1);
								await SignInPage.navigate(this.router);
								this.snackbarCtrl.open(this.translate.instant('new_password.success'), null, {
									duration: 2000,
								});
							} catch (e) {
								this.snackbarCtrl.open(this.translate.instant('new_password.error') + e, null, {
									duration: 2000,
								});
							}
						},
					},
				],
			});
		await passwordResetAlert.present();
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}

	async signInWithGoogle() {
		if (!this.loading) {
			this.loading = true;
			try {
				const result = (await Api.signIn(AuthStrategy.GOOGLE, environment.customerGroup)).data;
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signInWithFacebook() {
		if (!this.loading) {
			this.loading = true;
			try {
				const result = (await Api.signIn(AuthStrategy.FACEBOOK, environment.customerGroup)).data;
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signInWithApple() {
		if (!this.loading) {
			this.loading = true;
			try {
				const result = (await Api.signIn(AuthStrategy.APPLE, environment.customerGroup)).data;
				window.location.href = result.url;
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}
	

}
