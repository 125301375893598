import { Component, EventEmitter, Input, Output } from '@angular/core';
import ArticleGroup from '../../../models/ArticleGroup';
import { OrderListAction } from '../../enums/OrderListAction';
import Utils from '../../../utils';
import ArticleOption from '../../../models/ArticleOption';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import { OrderUtils } from '../../../utils/order-utils';
import { OrderType } from '../../../enums/OrderType';
import { PreorderType } from '../../../enums/PreorderType';
import Article from '../../../models/Article';
import Order from '../../../models/Order';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['order-list.component.scss'],
})
export class OrderListComponent {
	@Input() articleGroup: ArticleGroup;
	@Output() action = new EventEmitter<OrderListAction>();
	@Input() editable = true;
	@Input() order: Order;
	@Input() preorderType: PreorderType;

	Utils = Utils;
	OrderUtils = OrderUtils;
	ot = OrderType;

	constructor() {}

	edit() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.edit);
	}

	delete() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.delete);
	}

	async note() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.note);
	}

	add() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.add);
	}

	remove() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.remove);
	}

	priceOfOption(articleOption: ArticleOption): number {
		let price = +Utils.getPrice(articleOption.article, OrderType.PREORDER, this.preorderType);
		if (this.isOptionFullPrice(articleOption)) {
			price = price + this.articlePrice;
		}
		return price * articleOption.quantity;
	}

	isOptionFullPrice(articleOption: ArticleOption): boolean {
		const group = this.articleGroup.article.groups.find(grp => grp._id === articleOption.group);
		return group && group.displayIdentifiers && group.displayIdentifiers.findIndex(di => DisplayIdentifier.fullPrice === di) >= 0;
	}

	isSomeOptionFullPrice(): boolean {
		if (OrderUtils.totalPrice(this.articleGroup, OrderType.PREORDER, this.preorderType) === +this.articlePrice) {
			return true;
		}
		for (const articleOption of this.articleGroup.groups) {
			if (this.isOptionFullPrice(articleOption)) {
				return true;
			}
		}
		return false;
	}

	get articlePrice(): number {
		return Utils.getPrice(this.articleGroup.article, OrderType.PREORDER, this.preorderType);
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}
}
