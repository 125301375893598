const { name, version } = require('../../package.json');
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// PURINO

export const environment = {
	production: false,
	packageName: name,
	isAspasiaBuild: false,
	isMonzaBuild: false,
	isPurinoBuild: true,
	isBaseBuild: false,
	customerGroup: 'mymonza',
	webAppUrl: window.location.protocol + '//' + window.location.host,
	flavor: 'devDebug',
	placesApiKey: 'AIzaSyDRjmksPOJXofnZs1sDrFg4ZeF7LwxArQI',
	placesUrl: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDRjmksPOJXofnZs1sDrFg4ZeF7LwxArQI&libraries=places',
	baseUrl: 'https://dev-api.smoothr.de',
	customerAnalyticsId: 'UA-78275012-3',
	version,
	SUPPORT_EMAIL: 'mymonza@smoothr.de',
	firebaseConfig: {
		apiKey: 'AIzaSyBk9zyWDu-FlPIQ5n3fIC2pGurJijtu1GU',
		authDomain: 'mymonza-auth-dev.firebaseapp.com',
		databaseURL: 'https://mymonza-auth-dev.firebaseio.com',
		projectId: 'mymonza-auth-dev',
		storageBucket: 'mymonza-auth-dev.appspot.com',
		messagingSenderId: '68541309163',
		appId: '1:68541309163:web:e9793ff2f5a26e0f95deae',
		measurementId: 'G-SP7FDHVN7E'
	},
	VR_URL: 'https://test.vr-pay-ecommerce.de/v1/paymentWidgets.js',
	PAYPAL: {
		BN_CODE: 'SterlixGmbH_MP_Connected',
		JS_URL:
			'https://www.paypal.com/sdk/js?client-id=Aedq9GboSP4r-XKPNkl3WMnJ4HC8yf6_b6JeqgbvFWn2DXUOBMupresKVuoIN6ZlHVzA04Y1zh-tyDkM&integration-date=2020-09-04&components=buttons,funding-eligibility&currency=',
		JS_URL_2:
			'https://www.paypal.com/sdk/js?client-id=AYDMIg1C4j3uGnM7k0cZ2TJCkllONpdr8P9oTZaHJTsYx1vPWudzPgA_yTXF8BuLFtz5bRKFXRuxwKQg&integration-date=2020-09-04&components=buttons,funding-eligibility&currency=',
	},
	APAY_MERCHANT_ID: 'merchant.smoothr.mymonza-dev',
	GPAY: {
		GATEWAY: 'aciworldwide',
		MERCHANT_ID: 'BCR2DN6T5OYNT6CG'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
