import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform } from '@ionic/angular';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Api } from '../api/api';
import { MaintenancePage } from './pages/maintenance-page/maintenance.page';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	static largeScreen = false;

	constructor(
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private analytics: AngularFireAnalytics,
		platform: Platform,
		private ccService: NgcCookieConsentService,
		private translate: TranslateService,
		private swUpdate: SwUpdate,
	) {
		console.log(environment.customerGroup + ' ' + environment.version);
		translate.setDefaultLang('de');
		translate.use('de');
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		swUpdate.available.subscribe(() => {
			this.snackbarCtrl.open('Wir updaten grade deine App. Hab einen moment Geduld.', null, {
				duration: 2000,
			}).afterDismissed().subscribe(() => {
				window.location.reload();
			});
		});
		if (swUpdate.isEnabled) {
			console.log('Checking for App update');
			swUpdate.checkForUpdate().finally(() => console.log('Update checking finished'));
		} else {
			swUpdate.activateUpdate().finally(async () => {
				console.log('Updates activated result: ' + swUpdate.isEnabled);
				if (swUpdate.isEnabled) {
					console.log('Checking for App update');
					await swUpdate.checkForUpdate();
				} else {
					console.log('SwUpdate is disabled (no service worker)');
				}
			});
		}
		ccService.statusChange$.subscribe(async event => {
			await this.setAnalyticsEnabled(event.status !== ccService.getStatus().deny);
		});
		this.setAnalyticsEnabled(!ccService.hasAnswered() || ccService.hasConsented());
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					MaintenancePage.navigate(this.router);
				}
			});
	}

	ngOnInit() {
		this.translate
			.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
			.subscribe(data => {
				this.ccService.getConfig().content = this.ccService.getConfig().content || {};
				// Override default messages with the translated ones
				this.ccService.getConfig().content.message = data['cookie.message'];
				this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
				this.ccService.getConfig().content.allow = data['cookie.allow'];
				this.ccService.getConfig().content.deny = data['cookie.deny'];
				this.ccService.getConfig().content.link = data['cookie.link'];
				this.ccService.getConfig().content.policy = data['cookie.policy'];

				this.ccService.destroy(); // remove previous cookie bar (with default messages)
				this.ccService.init(this.ccService.getConfig()); // update config with translated messages
			});
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}
}
