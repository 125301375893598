import { Component, Input, OnInit,EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-account-toolbar',
  templateUrl: './account-toolbar.component.html',
  styleUrls: ['./account-toolbar.component.scss']
})
export class AccountToolbarComponent implements OnInit {
	@Input()
	title: string;
	@Input()
	icon: string;
	@Input()
	src: string;
	@Output() back = new EventEmitter<void>();

	constructor() {
	}

	ngOnInit() {
	}
	goBack() {
		this.back.emit();
	}

}
