import { RepositoryService } from '../services/repository/repository.service';
import Venue from '../../models/Venue';
import { Subscription } from 'rxjs';
import Order from '../../models/Order';
import Address from '../../models/Address';
import Customer from '../../models/Customer';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import Payment from '../../models/Payment';
import { AuthLoginResponse } from '../../models/AuthLoginResponse';

@Directive()
export default class RepositoryDirective implements OnInit, OnDestroy {
	public venue: Venue;
	public order: Order;
	public address: Address;
	public customer: Customer;
	public verifiedOrder: Order;
	public payment: Payment;
	public authLogin: AuthLoginResponse;
	private venueSubscription: Subscription;
	private orderSubscription: Subscription;
	private addressSubscription: Subscription;
	private customerSubscription: Subscription;
	private verifiedOrderSubscription: Subscription;
	private paymentSubscription: Subscription;
	private authLoginSubscription: Subscription;

	constructor(protected repository: RepositoryService) {
	}

	private static unsubscribe(subscription: Subscription) {
		if (subscription) {
			subscription.unsubscribe();
			subscription = null;
		}
	}

	ngOnInit() {
		this.init();
	}

	onInitFinish() {
	}

	ngOnDestroy() {
		RepositoryDirective.unsubscribe(this.paymentSubscription);
		RepositoryDirective.unsubscribe(this.venueSubscription);
		RepositoryDirective.unsubscribe(this.orderSubscription);
		RepositoryDirective.unsubscribe(this.addressSubscription);
		RepositoryDirective.unsubscribe(this.customerSubscription);
		RepositoryDirective.unsubscribe(this.verifiedOrderSubscription);
		RepositoryDirective.unsubscribe(this.authLoginSubscription);
		this.venue = null;
		this.order = null;
		this.address = null;
		this.customer = null;
		this.verifiedOrder = null;
		this.payment = null;
		this.authLogin = null;
	}

	onVenue() {
	}

	onVenueError(error) {
	}

	onOrder() {
	}

	onOrderError(error) {
	}

	onAddress() {
	}

	onAddressError(error) {
	}

	onCustomer() {
	}

	onCustomerError(error) {
	}

	onVerifiedOrder() {
	}

	onVerifiedOrderError(error) {
	}

	onPayment() {
	}

	onPaymentError(error) {
	}

	onAuthLogin() {
	}

	onAuthLoginError(error) {
	}

	private async init() {
		this.paymentSubscription = this.repository.payment.subscribe(
			payment => {
				this.payment = payment;
				this.onPayment();
			},
			error => {
				this.payment = null;
				this.onPaymentError(error);
			},
		);
		this.payment = this.repository._payment;
		this.onPayment();
		this.verifiedOrderSubscription = this.repository.verifiedOrder.subscribe(
			verifiedOrder => {
				this.verifiedOrder = verifiedOrder;
				this.onVerifiedOrder();
			},
			error => {
				this.verifiedOrder = null;
				this.onVerifiedOrderError(error);
			},
		);
		this.verifiedOrder = this.repository._verifiedOrder;
		this.onVerifiedOrder();
		this.orderSubscription = this.repository.order.subscribe(
			order => {
				this.order = order;
				this.onOrder();
			},
			error => {
				this.order = null;
				this.onOrderError(error);
			},
		);
		this.order = this.repository._order;
		this.onOrder();
		this.addressSubscription = this.repository.address.subscribe(
			address => {
				this.address = address;
				this.onAddress();
			},
			error => {
				this.address = null;
				this.onAddressError(error);
			},
		);
		this.address = this.repository._address;
		this.onAddress();
		this.customerSubscription = this.repository.customerAuth.subscribe(
			customerAuth => {
				this.customer = customerAuth && customerAuth.customer;
				this.onCustomer();
			},
			error => {
				this.customer = null;
				this.onCustomerError(error);
			},
		);
		this.customer = this.repository.customer;
		this.onCustomer();
		this.venueSubscription = this.repository.venue.subscribe(
			venue => {
				this.venue = venue;
				this.onVenue();
			},
			error => {
				this.venue = null;
				this.onVenueError(error);
			},
		);
		this.authLogin = this.repository._authLogin;
		this.onAuthLogin();
		this.authLoginSubscription = this.repository.authLogin.subscribe(
			authLogin => {
				this.authLogin = authLogin;
				this.onAuthLogin();
			},
			error => {
				this.authLogin = null;
				this.onAuthLoginError(error);
			},
		);
		this.venue = this.repository._venue;
		if (this.venue) {
			this.onVenue();
		}
		if (
			(this.venue === null && this.repository.getStoredVenueId()) ||
			(this.venue && this.venue._id !== this.repository.getStoredVenueId())
		) {
			try {
				await this.repository.getVenue();
			} catch (e) {
			}
		}
		this.onInitFinish();
	}
}
