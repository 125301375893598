import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Article from '../../../models/Article';
import { PreorderType } from '../../../enums/PreorderType';
import Utils from '../../../utils';
import { OrderType } from '../../../enums/OrderType';
import ArticleOption from '../../../models/ArticleOption';

@Component({
	selector: 'app-kl-overlay-option-item',
	templateUrl: './kl-overlay-option-item.component.html',
	styleUrls: ['kl-overlay-option-item.component.scss'],
})
export class KlOverlayOptionItemComponent implements OnInit {

	@Input() article: Article;
	@Input() preorderType: PreorderType;
	@Input() selected: boolean;
	@Input() currency: string;

	@Output() selection = new EventEmitter<Article>();

	utils = Utils;

	constructor() {
	}

	ngOnInit() {
	}

	get price(): number {
		return Utils.getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	selectArticle(article: Article) {
		this.selection.emit(article);
	}

}
