import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { HomePopoverComponent } from '../home-popover/home-popover.component';
import { PreorderType } from '../../../enums/PreorderType';
import { Router } from '@angular/router';

@Component({
	selector: 'app-modal-toolbar',
	templateUrl: './modal-toolbar.component.html',
	styleUrls: ['./modal-toolbar.component.scss'],
})
export class ModalToolbarComponent {
	@Input()
	showMenu = true;
	@Input()
	title: string;
	@Output()
	back = new EventEmitter<void>();
	@Input()
	type: string;
	pt = PreorderType;
	constructor(private popoverCtrl: PopoverController, private router: Router) {}
	async presentHomePopover(ev: any) {
		const popover = await this.popoverCtrl.create({
			component: HomePopoverComponent,
			event: ev,
			translucent: true,
			mode: 'ios',
			cssClass: 'home-popover',
		});
		return await popover.present();
	}
	goBack() {
		this.back.emit();
	}
}
