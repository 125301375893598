import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../models/OptionGroup';
import ArticleOption from '../../../models/ArticleOption';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import Article from '../../../models/Article';
import { PreorderType } from '../../../enums/PreorderType';
import Utils from '../../../utils';
import { OrderType } from '../../../enums/OrderType';

@Component({
	selector: 'app-article-option-group-toggle',
	templateUrl: './article-option-group-toggle.component.html',
	styleUrls: ['./article-option-group-toggle.component.scss'],
})
export class ArticleOptionGroupToggleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Output() openInfo = new EventEmitter<Article>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	utils = Utils;
	di = DisplayIdentifier;

	constructor() {}
	price(option: Article): number {
		let price = +Utils.getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += +Utils.getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		return price;
	}
	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0
		);
	}

	isSelected(article: Article) {
		return this.selected.findIndex(selected => selected.group === this.optionGroup._id && selected.article._id === article._id) >= 0;
	}

	select(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.group = this.optionGroup._id;
		option.quantity = 1;
		this.selection.emit(option);
	}
}
