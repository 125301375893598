import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import ArticleCategory from '../../../models/ArticleCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { ToOrderButtonComponent } from '../../components/to-order-button/to-order-button.component';
import { AlertController, IonSearchbar, ModalController } from '@ionic/angular';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import ArticleGroup from '../../../models/ArticleGroup';
import { AppComponent } from '../../app.component';
import { PreorderType } from '../../../enums/PreorderType';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderPage } from '../order/order.page';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../utils/order-utils';
import { OrderType } from '../../../enums/OrderType';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { environment } from '../../../environments/environment';
import Venue from '../../../models/Venue';
import { CheckDeliveryRadiusModalComponent } from '../../components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import Address from '../../../models/Address';
import RepositoryDirective from '../repository-directive';
import { HomePage } from '../home/home.page';
import { DisplayMode } from 'src/enums/DisplayMode';

@Component({
	selector: 'app-menu',
	templateUrl: '../../../projects/purino/pages/menu/menu.page.html',
	styleUrls: ['../../../projects/purino/pages/menu/menu.page.scss'],
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, { static: false }) toOrderButton;
	@ViewChild('categoryList', { static: false }) categoryList;
	@ViewChild('articleContent', { static: false }) articleList;
	@ViewChild(IonSearchbar, { static: false }) searchBar;

	selectedCategory: ArticleCategory = null;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	isValid = false;
	utils = Utils;
	orderUtils = OrderUtils;
	PreorderType = PreorderType;
	differenceToMvo: number;
	attempt = 1;
	searchBarOpen = false;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private router: Router,
		private analytics: AngularFireAnalytics,
	) {
		super(repository);
	}

	get active(): boolean {
		return this.order && OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null,
		) > 0;
	}

	get index(): number {
		return this.categories.findIndex(category => category._id === this.selectedCategory._id);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	async ngOnInit() {
		super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}

		if (environment.isAspasiaBuild) {
			this.loading = true;
			this.repository.venue.emit(await this.repository.getVenue(this.order && this.order.venue ? this.order.venue : 'aspasia_master'))
			this.reloadCategories();
			this.validate();
			this.loading = false;
		}
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		if (this.searchTerm !== '') {
			const inputElement = await this.searchBar.getInputElement();
			inputElement.value = '';
			this.searchTerm = '';
			this.reloadCategories();
			await this.delay(100);
			await this.scrollTo(index);
			return;
		}
		this.scrollEvents = false;
		this.selectedCategory = this.categories[index];
		await this.scrollArticleListTo(index);
		await this.scrollCategoryListTo(index);
		this.scrollEvents = true;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;
		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategory = this.categories[categoryIndex];
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	private async scrollCategoryListTo(index: number) {
		console.log('scrollCategoryListTo(' + index + ')');
		if (
			index < 0 ||
			!this.categoryList ||
			!this.categoryList.nativeElement ||
			!this.categoryList.nativeElement.children[index] ||
			(!this.categoryList.nativeElement.scrollTo && !this.categoryList.nativeElement.scrollLeft)
		) {
			console.log('scrollCategoryListTo(' + index + ') cancelled');
			return;
		}
		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		await this.delay(100);
	}

	private async scrollArticleListTo(index: number) {
		await this.articleList.scrollToPoint(0, this.articleList.el.children[index].offsetTop, 300);
	}

	async openModal(item: Article) {
		if (this.loading) {
			return;
		}
		if (!this.order && !OrderUtils.isDelivery(this.order)) {
			const result: { venue: Venue; address: Address }
				= await CheckDeliveryRadiusModalComponent.show(this.modalCtrl, this.venue);
			if (result) {
				this.loading = true;
				this.repository.venue.emit(await this.repository.getVenue(result.venue._id));
				this.repository.address.emit(result.address);
				this.repository.createOrder(this.venue, this.address, PreorderType.DELIVERY);
				this.loading = false;
			}
			return;
		}
		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(...Utils.defaultsToArticleOption(item, item.defaults, this.order.preorder.type));
		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup: JSON.parse(JSON.stringify(articleGroup)),
			},
			mode: 'ios',
			swipeToClose: !this.largeScreen(),
			presentingElement: await this.modalCtrl.getTop(),
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);
			this.repository.order.emit(this.order);
		}
	}
	onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
	}

	reloadCategories() {
		if (!this.venue) {
			this.categories = [];
			return;
		}
		const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = Utils.getAvailability(article, OrderType.PREORDER, PreorderType.DELIVERY);
					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase(),
						];
						return available &&
							keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
					}
					return available;
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
			console.log(this.categories)
		return;
	}

	validate() {
		if (!this.venue || !this.order || !this.order.preorder) {
			this.isValid = false;
			return;
		}
		const mov = this.order.preorder.type === PreorderType.DELIVERY ? +this.venue.movDelivery.$numberDecimal : 0;
		const orderValue = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null,
		);
		this.differenceToMvo = orderValue - mov;
		this.isValid = this.differenceToMvo >= 0;
	}

	onVenue() {
		super.onVenue();
		this.reloadCategories();
		this.selectedCategory = this.venue && this.categories.length > 0 ? this.categories[0] : null;
		this.validate();
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(Utils.axiosErrorToMessage(this.translate, error), null, {
			duration: 2000,
		});
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action'),
		);
	}

	onOrder() {
		super.onOrder();
		this.validate();
	}

	async delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	goBack() {
		HomePage.navigate(this.router);
	}
	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}
	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = !this.searchBarOpen;
		this.reloadCategories();
	}
}
