import Order from '../models/Order';
import { OrderType } from '../enums/OrderType';
import ArticleGroup from '../models/ArticleGroup';
import Venue from '../models/Venue';
import { PreorderType } from '../enums/PreorderType';
import ArticleOption from '../models/ArticleOption';
import OptionGroup from '../models/OptionGroup';
import FulfilledDependency from '../models/FulfilledDependency';
import { Moment } from 'moment';
import Utils from '../utils';
import { TimeUtils } from './time-utils';
import { TerminalorderType } from '../enums/TerminalorderType';
import { PromoCodeType } from '../models/PromoCodeType';
import { TranslateService } from '@ngx-translate/core';
import PromoCode from '../models/PromoCode';
import { DisplayIdentifier } from '../app/enums/DisplayIdentifier';
import Article from '../models/Article';
import { ValidationUtils } from './validation-utils';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AnalyticsUtils } from './analytics-utils';

export class OrderUtils {
	static sanitizePhoneCountry(order: Order) {
		if (OrderUtils.isDelivery(order) && !order.preorder.phoneCountry && order.preorder.phone) {
			const pc = Utils.phoneToPhoneCountryAndPhone(order.preorder.phone);
			order.preorder.phoneCountry = pc.phoneCountry;
			order.preorder.phone = pc.phone;
		}
	}
	static orderTotalPrice(order: Order): number {
		const totalPrice = OrderUtils.orderTotalPriceWithoutDiscounts(order);
		if (order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				case PromoCodeType.ABSOLUTE:
					return totalPrice - +order.promoCode.value;
				case PromoCodeType.RELATIVE:
					return totalPrice - (totalPrice - OrderUtils.getDeliveryFees(order)) * +order.promoCode.value;
				case PromoCodeType.DELIVERY_FEE:
					return totalPrice - OrderUtils.getDeliveryFees(order);
				case PromoCodeType.FREE_ARTICLE:
					return (
						totalPrice -
						OrderUtils.totalPrice(
							order.orderedArticles.find(artGrp => artGrp.isPromo),
							order.type,
							order.preorder.type,
						)
					);
				case PromoCodeType.BOGO:
					return (
						totalPrice -
						OrderUtils.bogoPrice(
							order.orderedArticles.find(artGrp => artGrp.isPromo),
							order.type,
							order.preorder.type,
						)
					);
			}
		}

		return totalPrice;
	}

	static bogoPrice(articleGroup: ArticleGroup, orderType: OrderType, preorderType: PreorderType): number {
		const basePrice = Utils.getPrice(articleGroup.article, orderType, preorderType);
		const fullPriceOptions = articleGroup.groups.filter(artOpt =>
			articleGroup.article.groups.find(
				grp => grp._id === artOpt.group && grp.displayIdentifiers.indexOf(DisplayIdentifier.fullPrice) >= 0,
			),
		);
		return (
			basePrice +
			fullPriceOptions
				.map(artOpt => Utils.getPrice(artOpt.article, orderType, preorderType) * artOpt.quantity)
				.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
		);
	}

	static isPreorder(order: Order): boolean {
		return order && order.type === OrderType.PREORDER && !!order.preorder;
	}

	static isDelivery(order: Order): boolean {
		return OrderUtils.isPreorder(order) && order.preorder.type === PreorderType.DELIVERY;
	}

	static isFreeDeliveryPromo(order: Order): boolean {
		return OrderUtils.isDelivery(order) && OrderUtils.hasPromo(order) && order.promoCode.type === PromoCodeType.DELIVERY_FEE;
	}

	static isBogoOrFreeArticlePromo(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			OrderUtils.hasPromo(order) &&
			(order.promoCode.type === PromoCodeType.BOGO || order.promoCode.type === PromoCodeType.FREE_ARTICLE)
		);
	}

	static isAbsoluteOrRelativePromo(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			OrderUtils.hasPromo(order) &&
			(order.promoCode.type === PromoCodeType.RELATIVE || order.promoCode.type === PromoCodeType.ABSOLUTE)
		);
	}

	static hasPromo(order: Order): boolean {
		return order && order.promoCode && order.promoCode._id && order.promoCode.type;
	}

	static applyPromo(translate: TranslateService, venue: Venue, order: Order, promoCode: PromoCode, analytics: AngularFireAnalytics): Order {
		if (OrderUtils.orderTotalPriceWithoutDiscounts(order) < promoCode.mov) {
			throw translate.instant('promo_code.mov_not_reached', {
				mov: Utils.numberToCurrency(promoCode.mov, order.currency),
			});
		}
		if (promoCode.type === PromoCodeType.DELIVERY_FEE && !OrderUtils.isDelivery(order)) {
			throw translate.instant('promo_code.free_delivery_wrong_type');
		}
		switch (promoCode.type) {
			case PromoCodeType.BOGO:
				// promoCode.value is array of article masterIds
				const possibleBogos = order.orderedArticles.filter(
					artGrp => promoCode.value.indexOf(artGrp.article.masterId) >= 0 || promoCode.value.indexOf(artGrp.article._id) >= 0,
				);
				if (possibleBogos.length === 0) {
					throw translate.instant('promo_code.no_bogo_in_cart');
				} else {
					let candidate = possibleBogos[0];
					let currentBogoPrice = OrderUtils.bogoPrice(candidate, order.type, order.preorder.type);
					for (const possibleBogo of possibleBogos) {
						const possibleBogoPrice = OrderUtils.bogoPrice(possibleBogo, order.type, order.preorder.type);
						if (possibleBogoPrice < currentBogoPrice) {
							currentBogoPrice = possibleBogoPrice;
							candidate = possibleBogo;
						}
					}
					const bogo: ArticleGroup = JSON.parse(JSON.stringify(candidate));
					bogo.quantity = 1;
					bogo.isPromo = true;
					OrderUtils.addToOrder(order, bogo, analytics);
				}
				break;
			case PromoCodeType.FREE_ARTICLE:
				const allArticles: Article[] = [];
				venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));
				// promoCode.value is  article masterId
				const freeArticle = allArticles.find(art => promoCode.value.indexOf(art.masterId) >= 0);
				const freeArticleGroup = new ArticleGroup();
				freeArticleGroup.article = freeArticle;
				freeArticleGroup.quantity = 1;
				freeArticleGroup.isPromo = true;
				freeArticleGroup.freeArticle = true;
				OrderUtils.addToOrder(order, freeArticleGroup, analytics);
				break;
		}
		order.promoCode = promoCode;
		return order;
	}

	static removePromo(order: Order): Order {
		const removedPromo = order.promoCode;
		order.promoCode = null;
		if (!removedPromo) {
			return order;
		}
		switch (removedPromo.type) {
			case PromoCodeType.DELIVERY_FEE:
			case PromoCodeType.ABSOLUTE:
			case PromoCodeType.RELATIVE:
				break;
			case PromoCodeType.BOGO:
			case PromoCodeType.FREE_ARTICLE:
				order.orderedArticles = order.orderedArticles.filter(artGrp => !artGrp.isPromo);
				break;
		}
		return order;
	}

	static orderTotalPriceWithoutDiscounts(order: Order): number {
		if (order === null) {
			return 0;
		}
		return (
			+OrderUtils.getDeliveryFees(order) +
			+order.orderedArticles
				.map(orderedArticle => {
					const casted: any = orderedArticle;
					if (casted.totalPrice) {
						if (casted.totalPrice.$numberDecimal) {
							return +casted.totalPrice.$numberDecimal;
						}
						return +casted.totalPrice;
					}
					return OrderUtils.totalPrice(
						orderedArticle,
						order.type,
						order.preorder ? order.preorder.type : null,
						order.terminalorder ? order.terminalorder.type : null,
					);
				})
				.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0)
		);
	}

	static articleGroupsTotalPrice(
		articleGroups: ArticleGroup[],
		orderType: OrderType,
		preorderType: PreorderType,
		terminalorderType: TerminalorderType = null,
	): number {
		return articleGroups
			.map(articleGroup => OrderUtils.totalPrice(articleGroup, orderType, preorderType, terminalorderType))
			.reduce((prev, curr) => prev + curr, 0);
	}

	static totalPrice(
		articleGroup: ArticleGroup,
		orderType: OrderType,
		preorderType: PreorderType,
		terminalOrderType: TerminalorderType = null,
	): number {
		return (
			(+Utils.getPrice(articleGroup.article, orderType, preorderType, terminalOrderType) +
				articleGroup.groups
					.map(option => {
						return +Utils.getPrice(option.article, orderType, preorderType, terminalOrderType) * option.quantity;
					})
					.reduce((prev, curr) => prev + curr, 0)) *
			articleGroup.quantity
		);
	}

	static injectRequiredArticles(venue: Venue, order: Order, analytics: AngularFireAnalytics) {
		if (!venue || !order) {
			return;
		}
		const requiredArticles = Utils.getRequiredArticles(venue, order.preorder.type);
		const requiredArticleGroups: ArticleGroup[] = [];
		for (const requiredArticle of requiredArticles) {
			if (!order.orderedArticles.find(oa => oa.article._id === requiredArticle._id)) {
				const ag = new ArticleGroup();
				ag.article = requiredArticle;
				ag.groups = Utils.defaultsToArticleOption(requiredArticle, requiredArticle.defaults, order.preorder.type);
				ag.quantity = 1;
				requiredArticleGroups.push(ag);
			}
		}
		for (const requiredArticleGroup of requiredArticleGroups) {
			console.log('Injecting: ' + requiredArticleGroup.article.name.de);
			OrderUtils.addToOrder(order, requiredArticleGroup, analytics);
		}
	}

	static injectDeliveryFees(venue: Venue, order: Order) {
		if (
			venue === null ||
			venue === undefined ||
			order === null ||
			order === undefined ||
			(!venue.deliveryFees || venue.deliveryFees.length <= 0) && (!venue.deliveryFeesPostalCodes || venue.deliveryFeesPostalCodes.length <= 0)
		) {
			if (order && order.preorder) {
				order.preorder.deliveryFee = undefined;
			}
			return;
		}
		if (order.preorder) {
			if (order.preorder.type === PreorderType.DELIVERY) {
				const articleSum = OrderUtils.articleGroupsTotalPrice(
					order.orderedArticles,
					order.type,
					order.preorder ? order.preorder.type : null,
					order.terminalorder ? order.terminalorder.type : null,
				);
				let candidate: any;
				if (order.preorder.postalCode && venue.deliveryFeesPostalCodes && venue.deliveryPostalCodes.length > 0) {
					candidate = venue.deliveryFeesPostalCodes.find(fee => fee.postalCode === order.preorder.postalCode);
					if (candidate) {
						order.preorder.deliveryFee = candidate.fee.$numberDecimal;
						return;
					}
				}
				candidate = venue.deliveryFees[0];
				for (const deliveryFee of venue.deliveryFees) {
					if (deliveryFee.from.$numberDecimal <= articleSum && candidate.fee.$numberDecimal > deliveryFee.fee.$numberDecimal) {
						candidate = deliveryFee;
					}
				}
				if (candidate) {
					order.preorder.deliveryFee = candidate.fee.$numberDecimal;
				} else {
					order.preorder.deliveryFee = undefined;
				}
			} else {
				order.preorder.deliveryFee = undefined;
			}
		}
	}

	static addSingle(selectedOptions: ArticleOption[], option: ArticleOption) {
		const relevantOptions = selectedOptions.filter(value => value.group !== option.group);
		while (selectedOptions.length) {
			selectedOptions.pop();
		}
		selectedOptions.push(...relevantOptions);
		option.quantity = 1;
		selectedOptions.push(option);
	}

	static addToOrder(order: Order, articleGroup: ArticleGroup, analytics: AngularFireAnalytics) {
		const currentPromoIndex = order.orderedArticles.findIndex(oa => oa.isPromo);
		if (
			currentPromoIndex >= 0 &&
			OrderUtils.isBogoOrFreeArticlePromo(order) &&
			order.promoCode.type === PromoCodeType.BOGO &&
			order.promoCode.value.indexOf(articleGroup.article.masterId) >= 0 &&
			OrderUtils.bogoPrice(articleGroup, order.type, order.preorder.type) <
			OrderUtils.bogoPrice(order.orderedArticles[currentPromoIndex], order.type, order.preorder.type)
		) {
			// new article is added and is bogo and is the smallest bogo price so there is no copy of this article.
			// remove isPromo Flag from previous article
			// add new article twice (once with isPromo true)
			order.orderedArticles[currentPromoIndex].isPromo = false;
			order.orderedArticles.push(articleGroup);
			const bogo = JSON.parse(JSON.stringify(articleGroup));
			bogo.isPromo = true;
			order.orderedArticles.push(bogo);
			AnalyticsUtils.addToCart(order, articleGroup, analytics);
			return;
		}
		const index = order.orderedArticles.findIndex(orderedArticle => {
			return (
				orderedArticle.article._id === articleGroup.article._id &&
				articleGroup.groups.length === orderedArticle.groups.length &&
				articleGroup.isPromo === orderedArticle.isPromo &&
				articleGroup.groups
					.map(
						option =>
							orderedArticle.groups.findIndex(
								orderedOption =>
									option.article._id === orderedOption.article._id &&
									option.quantity === orderedOption.quantity &&
									option.dependency === orderedOption.dependency &&
									option.dependsOn === orderedOption.dependsOn &&
									option.dependencyNumber === orderedOption.dependencyNumber,
							) >= 0,
					)
					.reduce((previousValue, currentValue) => previousValue && currentValue, true)
			);
		});
		if (index >= 0) {
			order.orderedArticles[index].quantity++;
			order.orderedArticles[index].isRecommendedRecipe =
				order.orderedArticles[index].isRecommendedRecipe || articleGroup.isRecommendedRecipe;
		} else {
			order.orderedArticles.push(articleGroup);
			AnalyticsUtils.addToCart(order, articleGroup, analytics);
		}
	}

	static addOption(options: ArticleOption[], option: ArticleOption, group: OptionGroup, fulfilledDependency: FulfilledDependency) {
		if (fulfilledDependency && fulfilledDependency.times > 0) {
			option.dependencyNumber = fulfilledDependency.times;
			option.dependsOn = fulfilledDependency.dependsOn;
			option.dependency = fulfilledDependency.dependency._id;
		}
		if (fulfilledDependency && fulfilledDependency.times < 0) {
			return;
		}
		const selection = options.filter(value => {
			return (
				option.group === value.group &&
				option.dependencyNumber === value.dependencyNumber &&
				option.dependsOn === value.dependsOn &&
				option.dependency === value.dependency
			);
		});
		if (group.limit === 1) {
			OrderUtils.addSingle(options, option);
			return;
		}
		const emptyIndex = options.findIndex(
			emptyOption =>
				emptyOption.group === group._id &&
				emptyOption.article.tags &&
				emptyOption.article.tags.find(tag => tag.identifier === 'empty') !== undefined,
		);
		const count =
			selection.map(value => value.quantity).reduce((previousValue, currentValue) => previousValue + currentValue, 0) +
			option.quantity;
		if (count > group.limit) {
			const indexOfFirst = options.indexOf(selection.find(value => value.article._id !== option.article._id));
			if (indexOfFirst >= 0) {
				if (options[indexOfFirst].quantity > 1) {
					options[indexOfFirst].quantity -= 1;
				} else {
					options.splice(indexOfFirst, 1);
				}
			}
		}
		const index = options.findIndex(value => value.article._id === option.article._id);
		if (index >= 0 && !group.hasMultiple) {
			Utils.removeFromArray(options, index);
		} else if (option.group === group._id && option.article.tags && option.article.tags.find(tag => tag.identifier === 'empty')) {
			if (emptyIndex >= 0) {
				Utils.removeFromArray(options, emptyIndex);
			} else {
				OrderUtils.addSingle(options, option);
			}
		} else {
			if (emptyIndex >= 0) {
				Utils.removeFromArray(options, emptyIndex);
			}
			const matchedIndex = options.findIndex(optionToMatch => {
				return (
					optionToMatch.group === option.group &&
					optionToMatch.article._id === option.article._id &&
					optionToMatch.dependency === option.dependency &&
					optionToMatch.dependsOn === option.dependsOn &&
					optionToMatch.dependencyNumber === option.dependencyNumber
				);
			});
			if (matchedIndex >= 0) {
				const requirements = options[matchedIndex].article.requirements;
				if (requirements) {
					if (
						(requirements.min !== -1 && requirements.min > options[matchedIndex].quantity + option.quantity) ||
						(requirements.max !== -1 && requirements.max < options[matchedIndex].quantity + option.quantity)
					) {
						console.log({
							message: 'Could not remove or add option',
							requirements,
							option: option.article.name.de,
							presentQuantity: options[matchedIndex].quantity,
							modifyBy: option.quantity,
						});
						return;
					}
				}
				options[matchedIndex].quantity += option.quantity;
				if (options[matchedIndex].quantity <= 0) {
					Utils.removeFromArray(options, matchedIndex);
				}
			} else {
				options.push(option);
			}
		}
	}

	static slotConflictingArticlesInOrder(slot: Moment, order: Order): ArticleGroup[] {
		const conflictingArticles: ArticleGroup[] = [];
		for (const articleGroup of order.orderedArticles) {
			if (!TimeUtils.doesHoursMatch(slot, articleGroup.article.availableHours)) {
				conflictingArticles.push(articleGroup);
			}
		}
		return conflictingArticles;
	}

	static getDeliveryFees(order: Order): number {
		if (OrderUtils.isDelivery(order)) {
			if (order.preorder.deliveryFee) {
				if (order.preorder.deliveryFee.$numberDecimal) {
					return +order.preorder.deliveryFee.$numberDecimal;
				} else {
					return +order.preorder.deliveryFee;
				}
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}

	static validateOrder(venue: Venue, order: Order): { valid: boolean; movDifference: number } {
		let mov = order.preorder.type === PreorderType.DELIVERY ? +venue.movDelivery.$numberDecimal : 0;
		const orderValue = OrderUtils.orderTotalPrice(order) - OrderUtils.getDeliveryFees(order);
		let articlesValid = order.orderedArticles.length !== 0;
		if (OrderUtils.hasPromo(order)) {
			mov = Math.max(mov, +order.promoCode.mov);
			if (OrderUtils.isAbsoluteOrRelativePromo(order)) {
				if (order.promoCode.type === PromoCodeType.ABSOLUTE) {
					mov = mov - +order.promoCode.value;
				} else if (order.promoCode.type === PromoCodeType.RELATIVE) {
					mov = mov - mov * +order.promoCode.value;
				}
			}
		}
		for (const oa of order.orderedArticles) {
			if (!ValidationUtils.areGroupsValid(oa, oa.article.groups)) {
				articlesValid = false;
				break;
			}
		}
		return {
			valid: orderValue > mov && articlesValid,
			movDifference: orderValue - mov,
		};
	}
}
