import { Component, Input } from '@angular/core';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';
import ArticleGroup from '../../../models/ArticleGroup';
import { OrderUtils } from '../../../utils/order-utils';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss'],
})
export class ItemViewComponent {
	@Input() article: Article;
	@Input() currency: string;
	@Input() preorderType: PreorderType;

	utils = Utils;
	ot = OrderType;

	constructor() {}

	get price(): number {
		if (this.article) {
			if (this.article.isFromPrice) {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.groups = Utils.defaultsToArticleOption(this.article, this.article.defaults, this.preorderType);
				articleGroup.quantity = 1;
				return OrderUtils.totalPrice(articleGroup, OrderType.PREORDER, this.preorderType);
			} else {
				return Utils.getPrice(this.article, OrderType.PREORDER, this.preorderType);
			}
		}
		return 0;
	}

}
